import React from "react";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import withLayout from "../hoc/withLayout/withLayout";
import { SEO } from "../components";

const submitLabel = state => {
  switch (state) {
    case "default":
      return "Save";
    case "submitting":
      return "Saving...";
    case "submitted":
      return "Saved";
    default:
      return "Save";
  }
};

const ConsentPage = () => {
  const { register, handleSubmit } = useForm();
  const [submitState, setSubmitState] = React.useState("default");

  const onSubmit = data => {
    setSubmitState("submitting");
    Cookies.set("opt-out-data-sales", data["opt-out-data-sales"] === true);
    setTimeout(() => {
      setSubmitState("submitted");
      window.location.reload();
    }, 1000);
  };

  let current = Cookies.get("opt-out-data-sales");

  return (
    <>
      <SEO title="Manage your data sales settings" />
      <div className="component-default-padding container mx-auto">
        <h1 className="mb-5 text-3xl text-primary-color lg:text-4xl">
          Manage your data sales settings
        </h1>
        <p className="mb-12 text-lg leading-normal text-secondary-color md:mb-20 lg:text-xl">
          Although Outer does not sell your personal information, from time to
          time we may use some of your personal information for advertising
          performance analysis and audience modeling for ongoing advertising,
          which may be interpreted as Data Sales under the California Consumer
          Privacy Act (CCPA). You can opt out of this here and we will ensure
          your data is no longer used for these purposes.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <input
              type="checkbox"
              className="mr-4"
              {...register("opt-out-data-sales")}
              defaultChecked={current === "true"}
            />
            <label htmlFor="opt-out-data-sales">Opt out of Data Sales</label>
          </div>

          <input
            className="mt-10 mb-20 inline-block cursor-pointer bg-outer-slate-blue p-4 text-outer-alpine-white"
            type="submit"
            value={submitLabel(submitState)}
          />
        </form>
      </div>
    </>
  );
};

export default withLayout(ConsentPage);
